.App {
  text-align: center;
}

body {
  font-family: 'Barlow Semi Condensed', sans-serif;
  color:white;
  background-color: #1a212c;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.landing-bg{
  background-image: url('/img/landing-bg.jpg');
  background-size:cover;
  background-repeat:no-repeat;
}

.filter-container {
  position:relative;
  z-index:2;
}

.max-1600{
  max-width:1600px;
}

.field-zone-field{
  min-height:600px;
  padding-bottom:50px;
}

.gap-arrow svg{
  margin:auto;
  margin-bottom:-10px;
}

.w-100 {
  width:100%;
}

.modal-max-height{
  max-height: 62.5vh;
}

.filter-button{
  position:absolute;
  right:0;
  top:30px;
  text-transform: capitalize;
  transition:0.25s ease-in-out;
}

.filter-button .filter-field-text{
  display:none;
}

.filter-button:hover .filter-field-text{
  display:inline-block;}

.filter-field-text{
  transition:0.25s ease-in-out;
}

.filter-button:hover{
  transition:0.25s ease-in-out;
}

.revert-button{
  position:absolute;
  right:0;
  top:90px;
  text-transform: capitalize;
  transition:0.25s ease-in-out;
}

.revert-button .revert-text{
  display:none;
}

.revert-button:hover .revert-text{
  display:inline-block;}

.revert-text{
  transition:0.25s ease-in-out;
}

.revert-button:hover{
  transition:0.25s ease-in-out;
}

.perspective-button{
  position:absolute;
  right:0;
  top:150px;
  text-transform: capitalize;
  transition:0.25s ease-in-out;
}

.perspective-button .perspective-text{
  display:none;
}

.perspective-button:hover .perspective-text{
  display:inline-block;}

.perspective-button:hover{
  transition:0.25s ease-in-out;
}

.ux-nav-link:hover{
  transition:0.25s ease-in-out;
}

.ux-nav-link{
  width:100%;
}

.ux-nav-link.hide-ux-nav-text{
  width:44px;
}

.ux-nav-link.hide-ux-nav-text:hover{
  width:100%;
}

.ux-nav-link.hide-ux-nav-text:hover .ux-nav-text{
  display:inline-block;
  white-space: nowrap;
  overflow:hidden;
  transition:0.25s ease-in-out;
}

.hide-ux-nav-text .ux-nav-text{
  display:none;
  transition:0.25s ease-in-out;
}

.filter-slideout{
  position:fixed;
  right:0;
  top:0px;
  padding:0;
  padding-top:40px;
  top:64px;
  min-width:300px;
  min-height:100vh;
}

.dark-bg{
  background:#0d151f;
}

.football-field {
  min-height:600px;
  max-width:1400px;
  margin:10px auto;
  perspective:50em;
  padding:10px 15%;
  margin-top:-130px;
  transition:0.75s ease-in-out;
}

.football-field > div:first-child {
  background:url('https://storage.googleapis.com/sportsiq/static/sports-iq-field-bg-dark.jpg');
  padding:40px 7%;
  padding-top:30px;
  border-radius:10px;
  background-size:cover;
  min-height:100%;
  transform: rotate3d(2, 0, 0, 45deg);
  transform-style: preserve-3d;
  background-position: center bottom;
  transition:0.75s ease-in-out;
}

.football-field.field-2d {
  min-height:650px;
  max-width:1400px;
  margin:10px auto;
  perspective:0em;
  padding:10px 80px;
  padding-bottom:20px;
  margin-top:0px;
}

.football-field.field-2d > div:first-child {
  background:url('https://storage.googleapis.com/sportsiq/static/sports-iq-field-bg-dark.jpg');
  padding:40px 7%;
  padding-top:30px;
  border-radius:10px;
  background-size:cover;
  min-height:100%;
  transform: rotate3d(2, 0, 0, 0deg);
  transform-style: preserve-3d;
  background-position: center bottom;
}

.football-field div {
  transform-style: inherit;
}

table tr th.w-100{
  width:100%;
}
table tr th.w-60{
  width:60%;
}
table tr th.w-50{
  width:50%;
}
table tr th.w-40{
  width:40%;
}
table tr th.w-35{
  width:35%;
}
table tr th.w-33{
  width:33%;
}
table tr th.w-30{
  width:30%;
}
table tr th.w-25{
  width:25%;
}
table tr th.w-20{
  width:20%;
}
table tr th.w-15{
  width:15%;
}
table tr th.w-10{
  width:10%;
}

.loader {
	background: rgba(94, 255, 0,0.95);
	border-radius: 50%;
	margin: 10px;
	height: 25px;
	width: 25px;
  line-height: 25px;
  text-shadow: 0px 0px 3px rgba(0, 51, 11, 0.5);
  color:#fff;


	box-shadow: 0 0 0 0 rgba(81, 255, 0,0.95);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(81, 255, 0,0.6);
	}

	70% {
		transform: scale(1);
    box-shadow: 0 0 0 10px rgba(81, 255, 0,0.95);
	}

	100% {
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(81, 255, 0,0);
	}
}

.football-field > div .stat-group:hover {
  /* transformation matrix:
  translate about (0, 20, 0)
  θ = 10 deg rotation about (1, 0, 0)
  [[1,      0,     0, 0]
   [0,  cos θ, sin θ, 0]
   [0, -sin θ, cos θ, 0]
   [0,      0,    20, 1]] */
  transform: matrix3d(1, 0,0,0,0,0.98480775301221,-0.17364817766693,0,0,0.17364817766693,0.98480775301221,0,0,0,20,1);
  transition: 0.2s ease-in-out;
}

.stat-group .swing-flat{
  max-height:100px;
}

.stat-group .curl-flat{
  max-height:150px;
  min-height:150px;
}

textarea {
  font-family: 'Menlo', monospace;
}